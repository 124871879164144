import React from 'react';

import Button from '@ui/Button';

const UnsubscribeForm = () => {
  return (
    <form>
      <Button fullWidth href="/user/edit" type="submit">
        Re-Enable Notifications
      </Button>
    </form>
  );
};

export default UnsubscribeForm;
