import type { $TSFixMe, SidebarCategory } from '@readme/iso';

import Oas from 'oas';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { MemoryRouter } from 'react-router-dom';

import { isLight } from '@Hub/Theme';

import useClassy from '@core/hooks/useClassy';

import useOas from '@routes/Reference/hooks/useOas';
import oasToSidebar from '@routes/Reference/TestBed/lib/oasToSidebar';

import APILogsTable from '@ui/API/LogsTable';
import { logs } from '@ui/API/LogsTable/mock';
import Method from '@ui/API/Method';
import { createSchema } from '@ui/API/Schema';
import APISectionHeader from '@ui/API/SectionHeader';
import Button from '@ui/Button';
import DateLine from '@ui/DateLine';
import Flex from '@ui/Flex';
import Icon from '@ui/Icon';
import MockPlayground from '@ui/MockPlayground';
import Sidebar from '@ui/Sidebar';

import { useSignupFormContext } from '../context';

import { mockDevDashCategory, petstore } from './mock';
import MockNavItem from './MockNavItem';
import MockQuickNav from './MockQuickNav';
import styles from './style.module.scss';

interface Props {
  /** Whether on 'uploadOAS' step of form */
  isUploadOASStep: boolean;
  /** Whether to show nav item modules (should only be shown in 'createProject' and 'uploadOAS' steps) */
  showNavModules: boolean;
}

const MockHub: React.FC<Props> = ({ showNavModules, isUploadOASStep }) => {
  const bem = useClassy(styles, 'MockHub');
  const [referenceSidebarCategories, setReferenceSidebarCategories] = useState<SidebarCategory[]>();
  const [activeDoc, setActiveDoc] = useState<$TSFixMe | null>(null);

  const { control, setValue } = useSignupFormContext();

  const logo = useWatch({ control, name: 'appearance.logo' });
  const primaryColor = useWatch({ control, name: 'appearance.colors.main' });
  const docsPreview = useWatch({ control, name: 'docsPreview' });

  const isPrimaryTooLight = !!primaryColor && isLight(primaryColor);
  const showMockAPIPreview = !!referenceSidebarCategories;

  const { oas, operation } = useOas(docsPreview, activeDoc);

  const sidebarRefs = useMemo(() => {
    if (!docsPreview) {
      return [];
    }

    return oasToSidebar(new Oas(docsPreview));
  }, [docsPreview]);

  useEffect(() => {
    if (!docsPreview) return;

    // Add Developer Dashboard sidebar category section
    // @ts-expect-error this reasonably aligns with the sidebar shape
    sidebarRefs.unshift(mockDevDashCategory);

    // Use the last category to establish active doc
    const lastCategory = sidebarRefs[sidebarRefs.length - 1];
    const doc = lastCategory.pages[0].children.length ? lastCategory.pages[0].children[0] : lastCategory.pages[0];

    setReferenceSidebarCategories(sidebarRefs);
    setActiveDoc(doc);
  }, [docsPreview, sidebarRefs]);

  // Create the Params component
  // This mirrors <Params /> in packages/react/src/routes/Reference
  const Params = useMemo(() => {
    // This needs to be memoized! If it isn't we'll recreate the Params component everytime a state change happens,
    // which will cause the user to lose focus on their input.
    return createSchema(oas, operation);
    // We don't want to do a reference comparison for oas, we only care if the `id` changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oas?.api?._id, operation]);

  // Animation for Mockhub on Mousemovement
  const glowRef = useRef<HTMLDivElement>(null);
  const mockHubRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const card = mockHubRef.current;
    const glowElement = glowRef.current;
    if (!card || !glowElement) return;

    let bounds;

    const rotateToMouse = e => {
      const mouseX = e.clientX;
      const mouseY = e.clientY;
      const leftX = mouseX - bounds.x;
      const topY = mouseY - bounds.y;
      const center = {
        x: leftX - bounds.width / 2,
        y: topY - bounds.height / 2,
      };
      const distance = Math.sqrt(center.x ** 2 + center.y ** 2);
      // Card rotation
      card.style.transform = `
        rotate3d(
          ${-center.y / 100},
          ${center.x / 100},
          0,
          ${Math.log(distance) * 1}deg
        )
      `;
      // The glow that responds to mouse
      glowElement.style.backgroundImage = `
        radial-gradient(
          circle at
          ${center.x * 2 + bounds.width / 2}px
          ${center.y * 2 + bounds.height / 2}px,
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 0.05),
          rgba(255, 255, 255, 0.025)
        )
      `;
    };

    const handleMouseEnter = () => {
      bounds = card.getBoundingClientRect();
      document.addEventListener('mousemove', rotateToMouse);
    };

    const handleMouseLeave = () => {
      document.removeEventListener('mousemove', rotateToMouse);
      card.style.transform = '';
      glowElement.style.background = '';
    };

    card.addEventListener('mouseenter', handleMouseEnter);
    card.addEventListener('mouseleave', handleMouseLeave);

    // eslint-disable-next-line consistent-return
    return () => {
      card.removeEventListener('mouseenter', handleMouseEnter);
      card.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, []);

  return (
    <div ref={mockHubRef} className={bem('&')}>
      <div ref={glowRef} className={bem('-glow')}></div>
      <div
        className={bem('-header', isPrimaryTooLight && '-header_dark')}
        style={{ backgroundColor: primaryColor || 'initial' }}
      >
        {logo.length ? (
          <img alt="logo" className={bem('-header-logo')} src={logo?.[0]} />
        ) : (
          !!isUploadOASStep && (
            <Icon color={isPrimaryTooLight ? '#384248' : 'white'} isFont name="icon-readme-brandmark" size={18} />
          )
        )}
      </div>
      <div
        className={bem('-navbar', isPrimaryTooLight && '-navbar_dark')}
        style={{ backgroundColor: primaryColor || 'initial' }}
      >
        {!!showNavModules && (
          <>
            <MockNavItem type="landing">Home</MockNavItem>
            <MockNavItem type="guides">Guides</MockNavItem>
            <MockNavItem type="recipes">Recipes</MockNavItem>
            <MockNavItem isActive={isUploadOASStep} type="reference">
              API Reference
            </MockNavItem>
            <MockNavItem type="changelog">Changelog</MockNavItem>
            <MockNavItem type="discuss">Discussions</MockNavItem>
          </>
        )}
      </div>
      <div className={bem('-sidebar', showMockAPIPreview && '-sidebar_visible')}>
        {!!showMockAPIPreview && (
          <>
            <MemoryRouter>
              {/* Absorb navigation clicks in MemoryRouter */}
              <Sidebar activeDoc={activeDoc?.slug} categories={referenceSidebarCategories} header={<MockQuickNav />} />
            </MemoryRouter>
          </>
        )}
      </div>

      <div className={bem('-content', showMockAPIPreview && '-content_visible')}>
        {!!showMockAPIPreview && (
          <article className={bem('-content-main')}>
            <header>
              <h1 className={bem('-doc-title')}>{activeDoc?.title}</h1>
              <Flex align="baseline" gap="sm" justify="start">
                <Method type={activeDoc?.api?.method || ''} />

                <p className={bem('-doc-subtitle')}>{`${oas?.api?.servers?.[0]?.url || ''}/${activeDoc?.slug}`}</p>
              </Flex>
            </header>

            <hr className={bem('-divider')} />

            <section className={bem('-content-section')}>
              <APISectionHeader heading="Recent Requests" />
              <APILogsTable logs={logs} onRowSelect={() => {}} />
            </section>

            <section className={bem('-content-section')}>
              <Params oas={oas} operation={operation} />
            </section>

            <hr className={bem('-divider', '-divider_with-margin')} />

            <DateLine className={bem('-dateline')} icon="icon-watch" prefix="Updated" tag="p" time={new Date()} />
          </article>
        )}
      </div>

      <div className={bem('-playground', showMockAPIPreview && '-playground_visible')}>
        {!!showMockAPIPreview && (
          <MockPlayground method={(activeDoc?.api?.method || '').toUpperCase()} slug={activeDoc?.slug} />
        )}
      </div>
      {!!isUploadOASStep && !showMockAPIPreview && (
        <div className={bem('-shimmer')}>
          <div className={bem('-shimmer-sidebar')} />
          <div className={bem('-shimmer-content')}>
            <Flex align="stretch" gap="sm" grow={1} justify="center" layout="col">
              <span>
                Don&apos;t have your <br /> OpenAPI file?
              </span>
              <Button
                className={bem('-shimmer-content-button')}
                fullWidth
                kind="primary"
                onClick={() => {
                  setValue('docsPreview', { ...petstore, isExample: true, _id: Math.random() });
                }}
                size="sm"
                text
                type="submit"
              >
                Try example API
                <Icon name="arrow-right" />
              </Button>
            </Flex>
          </div>
          <div className={bem('-shimmer-playground')} />
        </div>
      )}
    </div>
  );
};

export default MockHub;
