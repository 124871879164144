import React, { useCallback, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';

import useClassy from '@core/hooks/useClassy';

import OnboardingCard from './components/Card';
import LoginForm from './components/Forms/LoginForm';
import OnboardingLayout from './components/Layout';
import styles from './style.module.scss';

const Login = () => {
  const bem = useClassy(styles, 'Onboarding');
  const [inputFocused, setInputFocused] = useState(false);
  const [showSignUpLink, setShowSignUpLink] = useState(true);

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const redirect = params.get('redirect');

  const handleInputFocus = useCallback(() => {
    setInputFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setInputFocused(false);
  }, []);

  return (
    <OnboardingLayout>
      <OnboardingCard isPasswordFocused={inputFocused}>
        <LoginForm
          onInputBlur={handleInputBlur}
          onInputFocus={handleInputFocus}
          redirect={redirect}
          setShowSignUpLink={setShowSignUpLink}
        />
      </OnboardingCard>
      {!!showSignUpLink && (
        <Link className={bem('-subtext')} to="/signup">
          Don’t have an account? Sign up
        </Link>
      )}
    </OnboardingLayout>
  );
};

export default Login;
