import React from 'react';

import useClassy from '@core/hooks/useClassy';

import styles from './style.module.scss';

interface Props {
  closeEyes?: boolean;
}

const Owlbert = ({ closeEyes }: Props) => {
  const bem = useClassy(styles, 'Owlbert');

  return (
    <div className={bem('__absolute-container')}>
      <div className={bem('__container')}>
        <div className={bem('__face')}></div>
        <div className={bem('__eyes', closeEyes && 'password')} />
        <div className={bem('__arm-down-left', closeEyes && 'password')} />
        <div className={bem('__arm-down-right', closeEyes && 'password')} />
        <div className={bem('__arm-up-left', closeEyes && 'password')} />
        <div className={bem('__arm-up-right', closeEyes && 'password')} />
      </div>
    </div>
  );
};

export default Owlbert;
