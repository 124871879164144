import React from 'react';

import OnboardingCard from './components/Card';
import LoginSSOForm from './components/Forms/LoginSSOForm';
import OnboardingLayout from './components/Layout';

const LoginSSO = () => {
  return (
    <OnboardingLayout>
      <OnboardingCard>
        <LoginSSOForm />
      </OnboardingCard>
    </OnboardingLayout>
  );
};

export default LoginSSO;
