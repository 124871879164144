import type { CategorySchema } from '@readme/backend/models/category/types';
import type Oas from 'oas';

import docsFromOpenApi from './docs-from-openapi';

/**
 * Helper util for generating a sidebar from an OAS spec
 * Used in preview.readme.io and in signup onboarding for displaying Hub previews of OAS
 */
export default function oasToSidebar(oas: Oas, isPlayground = false) {
  const title = oas?.api?.info?.title || 'API Endpoints';
  const slug = title.toLowerCase().replace(/ /g, '-');
  const basePath = isPlayground ? '' : '/reference';
  return [
    {
      _id: Math.random().toString(16),
      title,
      slug,
      reference: true,
      project: Math.random().toString(16),
      version: Math.random().toString(16),
      createdAt: new Date(),
      pages: docsFromOpenApi(oas, `api-${slug}`).map(doc => {
        return {
          ...doc,
          children: (doc.children || []).map(page => ({
            ...page,
            link_url: `${basePath}/${page.slug}${window?.location?.search}`,
          })),
        };
      }),
    },
  ] as CategorySchema[];
}
