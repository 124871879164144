import React from 'react';

import useClassy from '@core/hooks/useClassy';

import styles from './style.module.scss';

const MockQuickNav = () => {
  const bem = useClassy(styles, 'MockHub');

  return (
    <div className={bem('-quicknav')}>
      <button aria-keyshortcuts="Control+/ Meta+/" className={bem('-quicknav-btn')} onClick={() => {}}>
        JUMP TO
        <span className={bem('-quicknav-key')}>⌘</span>
      </button>
    </div>
  );
};

export default MockQuickNav;
