import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import useClassy from '@core/hooks/useClassy';
import { fetcher } from '@core/hooks/useReadmeApi';
import type { HTTPError } from '@core/utils/types/errors';

import Button from '@ui/Button';
import Icon from '@ui/Icon';
import Input from '@ui/Input';
import { RHFGroup } from '@ui/RHF';

import styles from '../SignupForm/style.module.scss';

interface FormData {
  password: string;
  /** Optional 2FA token (if enabled/required for user) */
  token?: string;
}

interface ResetPasswordResponse {
  redirectTo?: string;
  success?: boolean;
  twoFactor?: {
    error?: string;
    required: boolean;
    verified: boolean;
  };
}

interface Props {
  resetKey?: string;
}

const ResetPasswordForm: React.FC<Props> = ({ resetKey }) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showVerify2FA, setShowVerify2FA] = useState(false);

  const bem = useClassy(styles, 'SignupForm');

  const {
    control,
    formState: { errors },
    handleSubmit,
    setError,
  } = useForm<FormData>({
    defaultValues: {
      password: '',
    },
  });

  const hasError = !!errors?.password;

  const onSubmit = handleSubmit(async data => {
    try {
      setIsSubmitting(true);

      const formData = { ...data, reset_key: resetKey };

      const response = await fetcher<ResetPasswordResponse>('/reset', {
        method: 'POST',
        body: JSON.stringify(formData),
      });

      // If user needs to verify 2FA, show 2FA input
      if (response.twoFactor && !response.twoFactor.verified) {
        setShowVerify2FA(true);

        if (response.twoFactor.error) {
          setError('token', { type: 'manual', message: response.twoFactor.error });
        }

        return;
      }

      // Otherwise reset was successful, redirect to server response
      if (response.redirectTo) {
        setIsSubmitted(true);
        window.location.assign(response.redirectTo);
      }
    } catch (error) {
      const { info } = error as HTTPError;
      const { errors: validationErrors } = info as {
        errors: Record<string, { message: string; name: string }>;
      };
      const message = validationErrors.hashed_password?.message || 'Something went wrong, please try again.';

      setError('password', { type: 'manual', message });
    } finally {
      setIsSubmitting(false);
    }
  });

  return (
    <form className={bem('&')} onSubmit={onSubmit}>
      {!showVerify2FA && (
        <RHFGroup
          control={control}
          description={
            !hasError ? 'Password must have two of the following: lower case, uppercase, numbers and symbols' : ''
          }
          id="password"
          label="Password"
          minLength={8}
          name="password"
          required
        >
          {({ field }) => (
            <Input
              {...field}
              autoFocus
              disabled={isSubmitted || isSubmitting}
              placeholder="New Password"
              type="password"
            />
          )}
        </RHFGroup>
      )}

      {/* Show 2FA form input if enabled/required for user */}
      {!!showVerify2FA && (
        <RHFGroup
          control={control}
          id="token"
          label="Enter the 6-digit code displayed in your authenticator app"
          name="token"
          required
        >
          {({ field }) => (
            <Input {...field} autoFocus disabled={isSubmitted || isSubmitting} placeholder="Two Factor Code" />
          )}
        </RHFGroup>
      )}
      <hr className={bem('-divider')} />
      {isSubmitted ? (
        <Button fullWidth kind="primary" text>
          <Icon name="check" />
          Password reset successful
        </Button>
      ) : (
        <Button className={bem('-submit-btn')} disabled={isSubmitting} fullWidth type="submit">
          {showVerify2FA ? 'Reset Password' : 'Continue'}
        </Button>
      )}
    </form>
  );
};

export default ResetPasswordForm;
