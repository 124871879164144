import type { CSSProperties } from 'react';

import React, { useMemo } from 'react';

import useClassy from '@core/hooks/useClassy';

import styles from './style.module.scss';

interface Props {
  alpha?: number;
  className?: string;
  color?: string;
  /** In rems */
  size?: number;
}

const Orb = ({ className, color = 'green', size = 30, alpha = 0.2 }: Props) => {
  const bem = useClassy(styles, 'Orb');

  const rgb = `var(--${color}-rgb)`;
  const style = useMemo(
    () => ({
      '--orb-size': `${size}rem`,
      '--orb-rgb': rgb,
      '--orb-alpha': alpha,
    }),
    [alpha, rgb, size],
  );

  return <div className={bem('&', className)} style={style as CSSProperties} />;
};

export default React.memo(Orb);
