import React from 'react';

import useClassy from '@core/hooks/useClassy';

import styles from './style.module.scss';

const TermsAndConditions = () => {
  const bem = useClassy(styles, 'TermsAndConditions');

  return (
    <p className={bem('&')}>
      <a href="https://readme.com/privacy" rel="noreferrer" target="_blank">
        Privacy Policy
      </a>
      <a href="https://policies.google.com/privacy" rel="noreferrer" target="_blank">
        Protected by Google reCAPTCHA
      </a>
    </p>
  );
};

export default TermsAndConditions;
