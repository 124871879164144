import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

import useClassy from '@core/hooks/useClassy';
import { fetcher } from '@core/hooks/useReadmeApi';
import type { HTTPError } from '@core/utils/types/errors';

import Button from '@ui/Button';
import Icon from '@ui/Icon';
import Input from '@ui/Input';
import { RHFGroup } from '@ui/RHF';

import styles from '../SignupForm/style.module.scss';

interface FormData {
  email: string;
  password: string;
  /** Optional 2FA token (if enabled/required for user) */
  token?: string;
}

interface LoginResponse {
  error?: string;
  redirectTo?: string;
  success?: boolean;
  twoFactor?: {
    error?: string;
    required: boolean;
    verified: boolean;
  };
}

interface Props {
  onInputBlur?: () => void;
  onInputFocus?: () => void;
  /** Optional redirect query param to send user to after successful login */
  redirect?: string | null;
  setShowSignUpLink: (show: boolean) => void;
}

const LoginForm: React.FC<Props> = ({ onInputBlur, onInputFocus, redirect, setShowSignUpLink }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showVerify2FA, setShowVerify2FA] = useState(false);

  const bem = useClassy(styles, 'SignupForm');

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const onSubmit = handleSubmit(async data => {
    try {
      setIsLoading(true);

      const response = await fetcher<LoginResponse>(`/users/session${redirect ? `?redirect=${redirect}` : ''}`, {
        method: 'POST',
        body: JSON.stringify(data),
      });

      // If user needs to verify 2FA, show 2FA input
      if (response.twoFactor && !response.twoFactor.verified) {
        setShowVerify2FA(true);

        setShowSignUpLink(false);

        if (response.twoFactor.error) {
          setError('token', { type: 'manual', message: response.twoFactor.error });
        }

        return;
      }

      // Otherwise reset was successful, redirect to server response
      if (response.redirectTo) {
        window.location.assign(response.redirectTo);
      }
    } catch (error) {
      const { info } = error as HTTPError;
      const errorMessage = info as { error?: string };

      const message = errorMessage?.error || 'Something went wrong, please try again.';

      setError('root.server', { type: 'manual', message });
    } finally {
      setIsLoading(false);
    }
  });

  const hasError = !!errors?.root?.server;
  const errorMessage = errors?.root?.server?.message;

  /* eslint-disable jsx-a11y/tabindex-no-positive */
  return (
    <form
      className={bem('&')}
      data-hs-cf-bound="false" // Prevent form from being bound by HubSpot
      method="POST"
      onSubmit={onSubmit}
    >
      {!showVerify2FA && (
        <>
          <RHFGroup control={control} id="email" label="Email" name="email" required>
            {({ field }) => <Input {...field} autoFocus placeholder="example@email.com" tabIndex={1} type="email" />}
          </RHFGroup>

          <RHFGroup
            className={bem('-forgot-password')}
            control={control}
            id="password"
            label={
              <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <span>Password</span>
                <Link tabIndex={5} to="/forgot">
                  Forgot your password?
                </Link>
              </div>
            }
            name="password"
            required
          >
            {({ field }) => (
              <Input
                {...field}
                onBlur={onInputBlur}
                onFocus={onInputFocus}
                placeholder="••••••••"
                tabIndex={2}
                type="password"
              />
            )}
          </RHFGroup>
        </>
      )}

      {/* Show 2FA form input if enabled/required for user */}
      {!!showVerify2FA && (
        <RHFGroup
          control={control}
          id="token"
          label="Enter the 6-digit code displayed in your authenticator app"
          name="token"
          required
        >
          {({ field }) => <Input {...field} autoFocus placeholder="Two Factor Code" />}
        </RHFGroup>
      )}

      {/* Show any submit/server errors below fields */}
      {!!hasError && (
        <small className="FormGroup FormGroup-error" role="note">
          {errorMessage}
        </small>
      )}
      <hr className={bem('-divider')} />
      <Button
        className={bem('-submit-btn')}
        disabled={isLoading}
        fullWidth
        loading={isLoading}
        tabIndex={3}
        type="submit"
      >
        Log In
        {!isLoading && <Icon color="white" name="arrow-right" />}
      </Button>
    </form>
  );
  /* eslint-enable jsx-a11y/tabindex-no-positive */
};

export default LoginForm;
