import React from 'react';
import { useParams } from 'react-router-dom';

import OnboardingCard from './components/Card';
import ResetPasswordForm from './components/Forms/ResetPasswordForm';
import OnboardingLayout from './components/Layout';

const ForgotPassword = () => {
  const params = useParams();
  const { resetKey } = params as { resetKey?: string };

  return (
    <OnboardingLayout>
      <OnboardingCard>
        <ResetPasswordForm resetKey={resetKey} />
      </OnboardingCard>
    </OnboardingLayout>
  );
};

export default ForgotPassword;
