import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import useClassy from '@core/hooks/useClassy';
import { fetcher } from '@core/hooks/useReadmeApi';
import type { HTTPError } from '@core/utils/types/errors';

import Button from '@ui/Button';
import Input from '@ui/Input';
import { RHFGroup } from '@ui/RHF';

import styles from '../SignupForm/style.module.scss';

interface FormData {
  sso: string;
}

interface LoginSSOResponse {
  error?: string;
  redirectTo?: string;
  success?: boolean;
}

const LoginSSOForm: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);

  const bem = useClassy(styles, 'SignupForm');

  const { control, handleSubmit, setError } = useForm<FormData>({
    defaultValues: {
      sso: '',
    },
  });

  const onSubmit = handleSubmit(async data => {
    try {
      setIsLoading(true);

      const response = await fetcher<LoginSSOResponse>('/login/sso', {
        method: 'POST',
        body: JSON.stringify(data),
      });

      // Otherwise reset was successful, redirect to server response
      if (response.redirectTo) {
        window.location.assign(response.redirectTo);
      }
    } catch (error) {
      const { info } = error as HTTPError;
      const errorMessage = info as { error?: string };

      const message = errorMessage?.error || 'Something went wrong,please try again.';

      setError('sso', { type: 'manual', message });
    } finally {
      setIsLoading(false);
    }
  });

  return (
    <form className={bem('&')} onSubmit={onSubmit}>
      <RHFGroup control={control} id="sso" label="Organization ID" name="sso" required>
        {({ field }) => <Input {...field} autoFocus placeholder="my-organization-id" />}
      </RHFGroup>
      <hr className={bem('-divider')} />
      <Button className={bem('-submit-btn')} disabled={isLoading} fullWidth loading={isLoading} type="submit">
        Log In
      </Button>
    </form>
  );
};

export default LoginSSOForm;
