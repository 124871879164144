import type { Operation } from 'oas/operation';
import type { OASDocument } from 'oas/types';

import Oas from 'oas';

export function validateCustomExtensions(definition: OASDocument) {
  try {
    const oas = new Oas(definition);
    oas.validateExtensions();
  } catch (err) {
    return new Error(`README VALIDATION ERROR: ${err.message}`);
  }

  return null;
}

/**
 * @deprecated This has been moved into Gitto within the `ReferenceHandler`.
 */
function getFirstTag(tags: string[]) {
  if (tags && tags.length > 0) {
    const nonEmptyTags = tags.filter(tag => tag.trim().length);

    if (nonEmptyTags.length > 0) {
      return nonEmptyTags[0];
    }
  }

  return null;
}

/**
 * Determine if a given OpenAPI operation has any tags.
 *
 * @deprecated This has been moved into Gitto within the `ReferenceHandler`.
 * @see {@link https://docs.readme.com/guides/docs/openapi-categories-pages-subpages#section-subpages}
 */
function hasTags(operation: Operation) {
  return operation.schema.tags && getFirstTag(operation.schema.tags);
}

/**
 * Grab what we would use as a page title for a given OpenAPI operation.
 *
 * @deprecated This has been moved into Gitto within the `ReferenceHandler`.
 */
export function getPageTitle(operation: Operation) {
  return operation.getSummary() || (hasTags(operation) ? operation.path : operation.method);
}

/**
 * Grab what we would use as a page excerpt for a given OpenAPI operation.
 *
 * @deprecated This has been moved into Gitto within the `ReferenceHandler`.
 */
export function getPageExcerpt(operation: Operation) {
  const excerpt = operation.getDescription();
  if (!excerpt) {
    return undefined;
  }

  return excerpt;
}

/**
 * @deprecated This has been moved into Gitto within the `ReferenceHandler`.
 */
export function getTag(operation: Operation) {
  return hasTags(operation) ? (getFirstTag(operation.schema?.tags ?? []) as string) : operation.path;
}
