import React from 'react';

import useClassy from '@core/hooks/useClassy';

import styles from './style.module.scss';

const iconMap = new Map(
  Object.entries({
    landing: 'icon-landing-page-2',
    reference: 'icon-references',
    guides: 'icon-guides',
    recipes: 'icon-recipes',
    changelog: 'icon-changelog',
    discuss: 'icon-discussions',
  }),
);

interface Props {
  isActive?: boolean;
  type: string;
}

const NavItem: React.FC<Props> = ({ children, isActive = false, type }) => {
  const bem = useClassy(styles, 'MockHub');

  const iconClass = iconMap.get(type);

  return (
    <div className={bem('-navbar-item', isActive && '-navbar-item__active')}>
      <i className={bem('-navbar-item-icon', iconClass)} />
      {children}
    </div>
  );
};

export default NavItem;
