/**
 * This code has been taken from the docs generation code that powers https://preview.readme.io
 */
import type { PageSchema } from '@readme/backend/models/page/types';
import type Oas from 'oas';
import type { Operation } from 'oas/operation';

import { getTag } from '@readme/server-shared/openapi'; // eslint-disable-line readme-internal/no-restricted-imports

/**
 * Stubs out docs for a given OpenAPI definition
 */
export default function docsFromOpenApi(oas: Oas, apiSetting: string) {
  const docs: Partial<PageSchema>[] = [];
  const tags = {};

  const paths = oas.getPaths();
  const webhooks = oas.getWebhooks();

  /**
   * For a given operation, this function stubs out the page data to get it rendering in the preview site
   */
  function createPages(operation: Operation, isWebhook: boolean = false) {
    const tag = getTag(operation);
    if (tag) {
      if (!(tag in tags)) {
        tags[tag] = {
          _id: Math.random().toString(16),
          title: tag,
          type: 'basic',
          body: '',
          isBodyEmpty: true,
          slug: tag.toLowerCase().replace(/ /g, '-'),
          hidden: false,
          link_url: '',
          category: Math.random().toString(16),
          createdAt: new Date(),
          updatedAt: new Date(),
          project: Math.random().toString(16),
          version: Math.random().toString(16),
          parentDoc: null,
          children: [],
        };
      }
    }

    // Description, summary and parameters aren't methods, so skip them
    if (['description', 'parameters', 'summary'].includes(operation.method)) {
      return;
    }

    const doc: Partial<PageSchema> = {
      _id: Math.random().toString(16),
      title: operation.schema.summary || operation.path || tag,
      slug: operation.getOperationId(),
      type: isWebhook ? 'webhook' : 'endpoint',
      category: { apiSetting },
      link_url: '',
      api: {
        apiSetting: {},
        method: operation.method as PageSchema['api']['method'],
        webhook: isWebhook,
        // Uncomment this if you want to test custom code samples
        // examples: {
        //   codes: [
        //     {
        //       language: 'javascript',
        //       code: 'console.log(1);',
        //     },
        //     {
        //       language: 'curl',
        //       code: 'curl http://example.com',
        //     },
        //   ],
        // },
      },
      swagger: { path: operation.path },
      excerpt: operation.schema.description,
      body: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc et tempus tortor. Aliquam nec eros quis nibh ornare vestibulum ut in nisi. Mauris quis auctor nulla, blandit pulvinar elit.
  
  Fusce vel felis ullamcorper, luctus dui id, pulvinar tortor. Cras pharetra sem rutrum libero placerat sodales. Aenean ut ante et nisi interdum vehicula gravida a metus.`,
    };

    if (tag && tag in tags) {
      tags[tag].children.push(doc);
    } else {
      docs.push(doc);
    }
  }

  // Build up our list of tags (categories).
  Object.keys(paths).forEach(path => {
    Object.keys(paths[path]).forEach(method => {
      const operation = paths[path][method] as Operation;
      createPages(operation);
    });
  });

  // Now do the same for webhooks
  Object.keys(webhooks).forEach(path => {
    Object.keys(webhooks[path]).forEach(method => {
      const operation = webhooks[path][method] as Operation;
      createPages(operation, true);
    });
  });

  // Merge our tag pages with the rest of the docs.
  Object.keys(tags).forEach(tag => {
    docs.push(tags[tag]);
  });

  return docs;
}
