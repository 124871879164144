import { compareDesc } from 'date-fns';
import React, { useMemo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import type { APIKeyRequestRow } from '@core/types/metrics';

import Table from '@ui/API/LogsTable/Table/index';
import Spinner from '@ui/Spinner';

import classes from './style.module.scss';
import './style.scss';

const COLUMN_NAMES = {
  TIME: 'time',
  STATUS: 'status',
  USER_AGENT: 'user agent',
  ICON: ' ', // A blank key indicates no table header for said column
};

interface Props {
  className?: string;
  /** A log that is shown when specifically linked to via a share url. */
  featuredLog?: APIKeyRequestRow;
  /** A footer to be displayed below the table. */
  footer?: React.ReactNode;
  /** Flag that indicates if the data is currently loading. */
  isLoading?: boolean;
  /** Logs from the metrics backend. */
  logs?: APIKeyRequestRow[];
  /** Called with the log's id when the user clicks on any row. Not providing this function will disable pagination features. */
  onRowSelect: (key: string | null, log?: APIKeyRequestRow) => void;
  /** The selected row as defined by the parent */
  selectedRowId?: string | null;
  style?: React.CSSProperties;
}

function APILogsTable({
  className,
  isLoading = false,
  featuredLog,
  footer,
  logs,
  onRowSelect,
  selectedRowId = null,
  style,
}: Props) {
  const history = useHistory();

  const shownLogs = useMemo(() => {
    let logsToShow = logs || [];

    // If there is a featured log and it's not already in, add it to shown logs
    if (featuredLog && !logsToShow.some(log => log.id === featuredLog.id)) {
      logsToShow = [featuredLog, ...logsToShow];
    }

    // Sort logs shown by date (needed to sort featured log correctly when it's outside of latest 3 logs)
    return logsToShow.sort((a, b) => {
      return compareDesc(new Date(a.createdAt), new Date(b.createdAt));
    });
  }, [logs, featuredLog]);

  const handleRowClick = useCallback(
    (...args) => {
      const [, key] = args;
      if (selectedRowId === key) {
        // Unselect row
        onRowSelect(null);
        history.push('?');
        return;
      }

      const log = shownLogs.find(({ id }) => id === key);
      onRowSelect(key, log);
    },
    [selectedRowId, shownLogs, onRowSelect, history],
  );

  const emptyState = useMemo(() => {
    const text = isLoading ? 'Retrieving recent requests…' : 'Make a request to see history.';
    return <div className={classes.APILogsTable_empty}>{text}</div>;
  }, [isLoading]);

  return (
    <div className={`${className || ''} ${classes.APILogsTable} rm-APILogsTable`} style={style}>
      <Table
        columnNames={COLUMN_NAMES}
        condensed
        emptyState={emptyState}
        handleRowClick={handleRowClick}
        logs={shownLogs}
        selectedRowId={selectedRowId}
      >
        <colgroup>
          <col width="150px" />
          <col width="100px" />
          <col />
          <col width="40px" />
        </colgroup>
      </Table>

      {/* Footer will show recent requests sparkline + link to view all requests */}
      {!!footer && <footer className={classes['APILogsTable-footer']}>{footer}</footer>}

      {/* Custom loading spinner positioned at top right of table */}
      {!!isLoading && (
        <div className={classes.APILogsTable_loading}>
          <Spinner size="sm" />
        </div>
      )}
    </div>
  );
}

export default React.memo(APILogsTable);
