import React from 'react';
import { ReactTitle } from 'react-meta-tags';
import { Route, Switch } from 'react-router-dom';

import ForgotPassword from './ForgotPassword';
import Login from './Login';
import LoginSSO from './LoginSSO';
import ResetPassword from './ResetPassword';
import Signup from './Signup';
import Unsubscribe from './Unsubscribe';
import VerifySSOEmail from './VerifySSOEmail';

const OnboardingRouter = () => (
  <Switch>
    <Route path="/forgot/:email?">
      <ReactTitle title="Forgot Password · ReadMe" />
      <ForgotPassword />
    </Route>
    <Route path="/invite/:email">
      <ReactTitle title="Sign Up (Invite) · ReadMe" />
      <Signup />
    </Route>
    <Route path="/login/sso">
      <ReactTitle title="SSO Login · ReadMe" />
      <LoginSSO />
    </Route>
    <Route path="/login/:redirect?">
      <ReactTitle title="Login · ReadMe" />
      <Login />
    </Route>
    <Route path="/reset/:resetKey">
      <ReactTitle title="Reset Password · ReadMe" />
      <ResetPassword />
    </Route>
    {/* Direct project creation path from Project List Page */}
    <Route path="/signup/project/new">
      <ReactTitle title="New Project · ReadMe" />
      <Signup isDirectNewProjectCreation />
    </Route>
    <Route path="/signup">
      <ReactTitle title="Sign Up · ReadMe" />
      <Signup />
    </Route>
    <Route path="/unsubscribe/:hash">
      <ReactTitle title="Unsubscribe · ReadMe" />
      <Unsubscribe />
    </Route>
    <Route path="/verify-sso/:issuer/:email">
      <ReactTitle title="Verify Your Email · ReadMe" />
      <VerifySSOEmail />
    </Route>
  </Switch>
);

export default OnboardingRouter;
