import React, { forwardRef, useCallback } from 'react';

import useClassy from '@core/hooks/useClassy';

import Button from '@ui/Button';
import Flex from '@ui/Flex';
import Modal, { ModalBody, ModalFooter, ModalHeader } from '@ui/Modal';
import Title from '@ui/Title';

import styles from './style.module.scss';

interface Props {
  errorMessage?: string;
}

const ErrorModal = ({ errorMessage }: Props, ref: React.ForwardedRef<Modal | null>) => {
  const bem = useClassy(styles, 'ErrorModal');

  const onClose = useCallback(() => {
    (ref as React.MutableRefObject<Modal | null>).current?.toggle(false);
  }, [ref]);

  return (
    <>
      <Modal
        ref={ref}
        className={bem('&')}
        size="lg"
        style={{ position: 'relative' }}
        target="#modal-target"
        verticalCenter
      >
        <ModalHeader toggleClose={onClose}>
          <Flex gap={0} layout="col">
            <Title className={bem('-header-title')} level={4}>
              Oh no! Your OAS file is invalid
            </Title>
            <p className={bem('-header-body')}>
              Don&apos;t worry, this happens all the time… OAS is a wee bit complicated. If you need a hand,
              <Button
                className={bem('-error-modal-btn')}
                href="mailto:support@readme.io?subject=OAS Validation Help"
                link
              >
                we&apos;re happy to help!
              </Button>
            </p>
          </Flex>
        </ModalHeader>
        <ModalBody>
          <pre className={bem('-error-container')}>{errorMessage}</pre>
        </ModalBody>
        <ModalFooter>
          <Button bem={{ shale_text: true }} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default forwardRef(ErrorModal);
