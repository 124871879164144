import React, { useEffect, useState } from 'react';

import useClassy from '@core/hooks/useClassy';

import Icon from '@ui/Icon';

import styles from './style.module.scss';

const CTA = () => {
  const bem = useClassy(styles, 'CTA');
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    // Toggle isActive state every 5 seconds
    const interval = setInterval(() => {
      setIsActive(prevIsActive => !prevIsActive);
    }, 5000); // Duration of each phase (active/inactive)

    return () => clearInterval(interval); // Cleanup on component unmount
  }, []);

  return (
    <div>
      <div className={bem('-content')}>
        <div
          className={`${bem('-emoji')} ${bem('-emoji-waving')} ${isActive ? bem('-emoji-waving_active') : ''}`}
        ></div>
        <div className={bem('-container')}>
          <div className={bem('-title')}> Learn how developers use your API</div>
          <a
            className={bem('-demo')}
            href={'https://calendly.com/d/cpv8-g2h-4tg/readme-developer-dashboard-demo'}
            rel="noreferrer"
            target="_blank"
          >
            Request Demo
            <Icon name="arrow-right" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default CTA;
