import React from 'react';

import OnboardingCard from './components/Card';
import UnsubscribeForm from './components/Forms/UnsubscribeForm';
import OnboardingLayout from './components/Layout';

const Unsubscribe = () => {
  return (
    <OnboardingLayout>
      <OnboardingCard
        subtitle="You can always enable them from your profile."
        title="You're Unsubscribed from Notifications"
      >
        <UnsubscribeForm />
      </OnboardingCard>
    </OnboardingLayout>
  );
};

export default Unsubscribe;
