import type { OASDocument } from 'oas/types';

import React, { useContext } from 'react';
import { useFormContext, FormProvider, useForm } from 'react-hook-form';

import type { ConfigContextValue } from '@core/context';
import { ConfigContext, UserContext } from '@core/context';

import type { UploadImageResponse } from '@ui/ImageUploader';

export interface SignupFormValues {
  appearance: {
    colors: {
      main: string;
    };
    logo: UploadImageResponse;
  };
  /**
   * Processed OAS spec returned from /api-validation
   * used to preview uploaded OAS in the MockHub for valid specs
   * */
  docsPreview?: OASDocument & { isExample?: boolean };
  flags?: {
    superHub?: boolean;
  };
  hostedURL?: string;
  modules: {
    changelog: boolean;
    discuss: boolean;
    docs: boolean;
    landing: boolean;
    reference: boolean;
    tutorials: boolean;
  };
  name: string;
  oasFile?: File;
  subdomain: string;
  // Successful uploaded OAS ID
  successfulUploadId?: string;
}

interface Props {
  children: React.ReactNode;
  /**
   * Indicates if this is a direct new project creation navigation from Project List Page
   * via a navigation to `/signup/project/new`
   * */
  isDirectNewProjectCreation?: boolean;
}

/**
 * Sets up the React Hook Form instance that manages our project configuration
 * form. Renders it with a `FormProvider` that allows all child components to
 * access the form context and all of its properties.
 *
 * Child components should call `useSignupFormContext()` hook to access the
 * form context that is properly typed.
 *
 * @link https://react-hook-form.com/docs/formprovider
 */
export default function SignupFormProvider({ children, isDirectNewProjectCreation = false }: Props) {
  const { superHub } = useContext(ConfigContext) as ConfigContextValue;
  const { is_god: isGod } = useContext(UserContext);

  /**
   * Whether or not the SuperHub flag should be enabled for new project creation in signup
   * Derived from `SH_ENABLED_FOR_NEW_PROJECTS` env var from `ConfigContext`
   * along with the `isDirectNewProjectCreation` (`/signup/project/new` route from PLP) and `isGod` flags
   */
  const isSuperHubEnabled =
    superHub?.newProjectsEnabled && (!isDirectNewProjectCreation || (isGod && isDirectNewProjectCreation));

  const formValue = useForm<SignupFormValues>({
    defaultValues: {
      appearance: {
        colors: {
          main: '',
        },
        logo: [],
      },
      flags: {
        superHub: !!isSuperHubEnabled,
      },
      name: '',
      subdomain: '',
    },
  });

  return <FormProvider {...formValue}>{children}</FormProvider>;
}

/**
 * Convenience hook to get our React Hook Form Context with types that match our
 * project mapper form fields.
 * @link https://react-hook-form.com/docs/useformcontext
 */
export const useSignupFormContext = () => useFormContext<SignupFormValues>();
