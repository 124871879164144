import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import useClassy from '@core/hooks/useClassy';
import { fetcher } from '@core/hooks/useReadmeApi';

import Button from '@ui/Button';
import Flex from '@ui/Flex';
import Icon from '@ui/Icon';
import Input from '@ui/Input';
import { RHFGroup } from '@ui/RHF';

import styles from '../SignupForm/style.module.scss';

interface FormData {
  email: string;
}

interface Props {
  email?: string;
}

const ForgotPasswordForm: React.FC<Props> = ({ email }) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false); // New state to track submitting status
  const bem = useClassy(styles, 'SignupForm');

  const { control, handleSubmit } = useForm<FormData>({
    defaultValues: {
      email: email || '',
    },
  });

  const onSubmit = handleSubmit(async data => {
    try {
      setIsSubmitting(true);

      await fetcher('/forgot', {
        method: 'POST',
        body: JSON.stringify(data),
      });

      setIsSubmitted(true);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  });

  return (
    <form
      className={bem('&')}
      data-hs-cf-bound="false" // Prevent form from being bound by HubSpot
      onSubmit={onSubmit}
    >
      <RHFGroup control={control} id="email" label="Email" name="email" required>
        {({ field }) => (
          <Input
            {...field}
            autoFocus
            disabled={isSubmitted || isSubmitting} // Disable input on submit or success
            placeholder="owlbert@readme.io"
            type="email"
          />
        )}
      </RHFGroup>
      <hr className={bem('-divider')} />
      {isSubmitted ? (
        <Flex align="center" className={bem('-success-message')} gap="xs" justify="center">
          <Icon name="check" />
          Check your email for a link to reset password
        </Flex>
      ) : (
        <Button className={bem('-submit-btn')} disabled={isSubmitting} fullWidth type="submit">
          Send Reset Link
        </Button>
      )}
    </form>
  );
};

export default ForgotPasswordForm;
