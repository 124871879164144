import { subDays } from 'date-fns';

import type { APIKeyRequestRow } from '@core/types/metrics';

const today = new Date();
const twoDaysAgo = subDays(today, 2);
const oneWeekAgo = subDays(today, 7);

export const logs: APIKeyRequestRow[] = [
  {
    id: '00f4085f-ba5c-4ee2-8b51-90 4cad33d8ad',
    createdAt: today.toISOString(),
    startedDateTime: today.toISOString(),
    groupId: 'sha512-UB6fW+uMswajiPQNeyNeOUaE6p+EcAI4Uv6iV7jVjpFrfq2aR77FdVMFGkEPBY2698LT3NIKpwzcRwz2Rylntg==?167d',
    groupLabel: 'developers',
    groupEmail: 'gkoberger@gmail.com',
    method: 'GET',
    status: 200,
    url: 'https://dash.readme.com/api/v1/categories?perPage=10&page=1',
    path: '/categories',
    useragent: {
      name: 'ReadMe-API-Explorer',
      platform: 'ReadMe',
    },
    operationId: 'getCategories',
    company: {
      name: '',
      logo: '',
    },
  },
  {
    id: 'd295b6ee-fa6b-488d-b5a2-1e2d004d260f',
    createdAt: twoDaysAgo.toISOString(),
    startedDateTime: twoDaysAgo.toISOString(),
    groupId: 'sha512-UB6fW+uMswajiPQNeyNeOUaE6p+EcAI4Uv6iV7jVjpFrfq2aR77FdVMFGkEPBY2698LT3NIKpwzcRwz2Rylntg==?167d',
    groupLabel: 'developers',
    groupEmail: 'gkoberger@gmail.com',
    method: 'GET',
    status: 200,
    url: 'https://dash.readme.com/api/v1/categories?perPage=10&page=1',
    path: '/categories',
    useragent: {
      name: 'ReadMe-API-Explorer',
      platform: 'ReadMe',
    },
    operationId: 'getCategories',
    company: {
      name: '',
      logo: '',
    },
  },
  {
    id: '5dd79253-ba2e-4cf0-b2af-817a58c61c77',
    createdAt: oneWeekAgo.toISOString(),
    startedDateTime: oneWeekAgo.toISOString(),
    groupId: 'sha512-UB6fW+uMswajiPQNeyNeOUaE6p+EcAI4Uv6iV7jVjpFrfq2aR77FdVMFGkEPBY2698LT3NIKpwzcRwz2Rylntg==?167d',
    groupLabel: 'developers',
    groupEmail: 'gkoberger@gmail.com',
    method: 'POST',
    status: 201,
    url: 'https://dash.readme.com/api/v1/categories',
    path: '/categories',
    useragent: {
      name: 'ReadMe-API-Explorer',
      platform: 'ReadMe',
    },
    operationId: 'createCategory',
    company: {
      name: '',
      logo: '',
    },
  },
];

export const featuredLog = {
  id: '19a4ea3c-509c-43e9-a36b-d1ebe10b370e',
  createdAt: today.toISOString(),
  startedDateTime: today.toISOString(),
  groupId: 'sha512-UB6fW+uMswajiPQNeyNeOUaE6p+EcAI4Uv6iV7jVjpFrfq2aR77FdVMFGkEPBY2698LT3NIKpwzcRwz2Rylntg==?167d',
  groupLabel: 'developers',
  groupEmail: 'gkoberger@gmail.com',
  method: 'POST',
  status: 400,
  url: 'https://dash.readme.com/api/v1/categories',
  path: '/categories',
  useragent: {
    name: 'ReadMe-API-Explorer',
    platform: 'ReadMe',
  },
  operationId: 'createCategory',
  company: {
    name: '',
    logo: '',
  },
};
