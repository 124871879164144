import React from 'react';

import useClassy from '@core/hooks/useClassy';

import Box from '@ui/Box';
import Title from '@ui/Title';

import Owlbert from '../Owlbert/index';

import styles from './style.module.scss';

interface Props {
  children: React.ReactNode;
  isAdTraffic?: boolean;
  isPasswordFocused?: boolean;
  subtitle?: string;
  title?: string;
}

const OnboardingCard = ({ title, subtitle, isAdTraffic, isPasswordFocused, children }: Props) => {
  const bem = useClassy(styles, 'OnboardingCard');

  return (
    <div className={bem('-owlbert-container')}>
      <Owlbert closeEyes={isPasswordFocused} />
      <Box className={bem('&', '-override-border')} kind="card">
        {!!title && (
          <Title className={bem('&-title', isAdTraffic && '&_adTitle')} level={1}>
            {title}
          </Title>
        )}
        {!!subtitle && (
          <Title className={bem('&-subtitle')} level={2}>
            {subtitle}
          </Title>
        )}
        {children}
      </Box>
    </div>
  );
};

export default OnboardingCard;
