import type { Path } from 'react-hook-form';

import { SignupSource } from '@readme/iso';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useForm } from 'react-hook-form';

import type { ConfigContextValue } from '@core/context';
import { ConfigContext } from '@core/context';
import useClassy from '@core/hooks/useClassy';
import useDebounced from '@core/hooks/useDebounced';
import { fetcher } from '@core/hooks/useReadmeApi';
import type { HTTPError } from '@core/utils/types/errors';

import Button from '@ui/Button';
import Icon from '@ui/Icon';
import Input from '@ui/Input';
import { RHFGroup } from '@ui/RHF';

import styles from './style.module.scss';

interface FormData {
  email: string;
  name: string;
  password: string;
}

interface LookupEmailResponse {
  name: string;
}

type ErrorObject = Record<string, string>;

interface Props {
  hash?: string | null;
  invitedEmail?: string;
  isInviteSignup?: boolean;
  onInputBlur?: () => void;
  onInputFocus?: () => void;
  /** Signup success callback */
  onSuccess: (data: FormData) => void;
}

function mapServerErrors(key: string) {
  switch (key) {
    case 'hashed_password':
      return 'password';
    case 'recaptcha':
      /**
       * Recaptcha errors will not be attached to any field input, so
       * we'll use root key here to set it as a server error
       * @see {@link: https://react-hook-form.com/docs/useform/seterror}
       */
      return 'root.serverErrorRecaptcha';
    case 'ValidationError':
      return '';
    default:
      return key;
  }
}

const SignupForm = ({ hash, invitedEmail, isInviteSignup, onInputFocus, onInputBlur, onSuccess }: Props) => {
  const bem = useClassy(styles, 'SignupForm');
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const { readmeRecaptchaSiteKey } = useContext(ConfigContext) as ConfigContextValue;

  const {
    control,
    getValues,
    handleSubmit,
    setError,
    setValue,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      email: invitedEmail || '',
      name: '',
      password: '',
    },
  });

  const [isLoading, setIsLoading] = useState(false);
  const [saveError, setSaveError] = useState<ErrorObject | null>(null);

  useEffect(() => {
    if (!saveError || !saveError.errors) return;
    // Manually set errors from the API response to the form state.
    Object.entries(saveError.errors).forEach(([key, message]) => {
      // We need to map certain server errors to the correct form field
      // or set it as a server error on the root key
      const name = mapServerErrors(key) as unknown as Path<FormData>;

      if (name) {
        setError(name, { type: 'manual', message });
      }
    });
  }, [saveError, setError]);

  const emailLookup = useCallback(
    async expandedEmail => {
      const name = getValues('name');

      // If we already have a name, don't bother looking up the email
      if (name?.length) return;

      try {
        const response = await fetcher<LookupEmailResponse>(`/email-lookup?email=${expandedEmail}`, {
          method: 'GET',
        });

        if (response && response.name) {
          setValue('name', response.name);
        }
      } catch (error) {
        // This is a non-critical error, so we don't need to do anything
      }
    },
    [getValues, setValue],
  );

  const debouncedEmailLookup = useDebounced(emailLookup, 300);

  const checkEmail = useCallback(
    (email: string) => {
      // See if we can find a name for this email
      if (email.match(/(.*)@(.*)\.[a-zA-Z0-9]{3,10}/)) {
        // Note: debounce to not fire on every keystroke
        debouncedEmailLookup(email);
      }
    },
    [debouncedEmailLookup],
  );

  const onSubmitSuccess = (data: FormData) => {
    setIsLoading(false);
    onSuccess(data);
  };

  const onSubmit = handleSubmit(async data => {
    try {
      setIsLoading(true);

      let recaptchaResponse;

      if (readmeRecaptchaSiteKey) {
        recaptchaRef.current?.reset();

        try {
          recaptchaResponse = await recaptchaRef.current?.executeAsync();
        } catch (error) {
          // Don't throw any error here, just continue
          // Any reCaptcha errors will be logged in Sentry via validateCaptcha middleware
        }
      }

      await fetcher('/users', {
        method: 'POST',
        body: JSON.stringify({
          ...data,
          'g-recaptcha-response': recaptchaResponse || '',
          hash: hash || undefined,
          source: SignupSource.Signup,
          // Indicate if this is an invite signup, invited user signups don't go on to create projects
          isInviteSignup,
        }),
      });

      onSubmitSuccess(data);
    } catch (error) {
      // Our fetcher stores the initial response data on `error.info`
      const { info } = error as HTTPError;
      setIsLoading(false);
      setSaveError(info as ErrorObject);
    }
  });

  const hasRecaptchaError = !!errors?.root?.serverErrorRecaptcha;

  return (
    <form className={bem('&')} id="signup-form" method="POST" onSubmit={onSubmit}>
      {!!readmeRecaptchaSiteKey && (
        <ReCAPTCHA ref={recaptchaRef} badge="bottomleft" sitekey={readmeRecaptchaSiteKey} size="invisible" />
      )}

      <RHFGroup control={control} id="email" label="Email" name="email" required>
        {({ field }) => (
          <Input
            {...field}
            autoFocus={!isInviteSignup}
            onChange={event => {
              const value = event.target.value;
              field.onChange(value);
              checkEmail(value);
            }}
            placeholder="owlbert@readme.io"
            type="email"
          />
        )}
      </RHFGroup>

      <RHFGroup control={control} id="name" label="Full Name" name="name" required>
        {({ field }) => <Input {...field} autoFocus={isInviteSignup} placeholder="Full Name" />}
      </RHFGroup>

      <RHFGroup control={control} id="password" label="Password" minLength={8} name="password" required>
        {({ field }) => (
          <Input {...field} onBlur={onInputBlur} onFocus={onInputFocus} placeholder="••••••••" type="password" />
        )}
      </RHFGroup>

      {!!hasRecaptchaError && (
        <small className="FormGroup FormGroup-error" role="note">
          Something went wrong validating reCAPTCHA, please try again.
        </small>
      )}

      <hr className={bem('-divider')} />

      <Button className={bem('-submit-btn')} fullWidth loading={isLoading} type="submit">
        Sign Up
        {!isLoading && <Icon color="white" name="arrow-right" />}
      </Button>
    </form>
  );
};

export default SignupForm;
