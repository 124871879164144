import type { SignupFormValues } from '../../context';
import type { ProjectClientSide } from '@readme/backend/models/project/types';
import type { Path } from 'react-hook-form';

import { SignupSource } from '@readme/iso';
import { FastAverageColor } from 'fast-average-color';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useWatch } from 'react-hook-form';
import tinycolor from 'tinycolor2';

import { UserContext } from '@core/context';
import useClassy from '@core/hooks/useClassy';
import useDebounced from '@core/hooks/useDebounced';
import { fetcher } from '@core/hooks/useReadmeApi';
import type { HTTPError } from '@core/utils/types/errors';

import Button from '@ui/Button';
import ColorPicker from '@ui/ColorPicker';
import Tooltip from '@ui/Dash/PageNav/Tooltip';
import Flex from '@ui/Flex';
import Icon from '@ui/Icon';
import type { UploadImageResponse } from '@ui/ImageUploader';
import ImageUploader from '@ui/ImageUploader';
import Input from '@ui/Input';
import InputGroup from '@ui/InputGroup';
import { RHFGroup } from '@ui/RHF';
import Spinner from '@ui/Spinner';
import Toggle from '@ui/Toggle';

import { useSignupFormContext } from '../../context';
import styles from '../SignupForm/style.module.scss';

// Default logo values to use if user doesn't upload their own
export const DEFAULT_LOGO = [
  'https://files.readme.io/45785f4-brandmark-blue.svg',
  'readme.svg',
  60,
  60,
  '#018EF5',
] as UploadImageResponse;

function mapServerErrors(key: string) {
  switch (key) {
    case 'subpath':
      /**
       * Subpath errors (tied to subdomain) will not be attached to any field input, so
       * we'll use root key here to set it as a generic server error
       * @see {@link: https://react-hook-form.com/docs/useform/seterror}
       */
      return 'root.server';
    case 'ValidationError':
      return '';
    default:
      return key;
  }
}

/**
 * Simple util to generate a random string of lowercase letters for making subdomain slugs unique
 */
function generateSlugHash(length = 4) {
  const alphabet = 'abcdefghijklmnopqrstuvwxyz';

  return Array.from({ length })
    .map(() => {
      return alphabet.charAt(Math.floor(Math.random() * alphabet.length));
    })
    .join('');
}

function slugify(text: string) {
  return text
    .toLowerCase()
    .trim()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^a-z0-9-]/g, '') // Remove all non-alphanumeric chars
    .replace(/-+/g, '-') // Replace back-to-back dashes with a single dash
    .replace(/^-+|-+$/g, ''); // Remove leading and trailing dashes
}

interface SubdomainLookupResponse {
  exists: boolean;
}

interface Props {
  /** Whether this is a direct project creation (i.e. coming in from /signup/project/new) */
  isDirectNewProjectCreation?: boolean;
  /** Project creation success callback */
  onSuccess: (project: ProjectClientSide) => void;
}

const CreateProjectForm = ({ isDirectNewProjectCreation, onSuccess }: Props) => {
  const bem = useClassy(styles, 'SignupForm');
  const [isLoading, setIsLoading] = useState(false);
  const [showSubdomainField, setShowSubdomainField] = useState(false);
  const [isSubdomainLookupLoading, setIsSubdomainLookupLoading] = useState(false);
  const [subdomainLookupExists, setSubdomainLookupExists] = useState<boolean | null>(null);

  const { is_god: isGod } = useContext(UserContext);

  const {
    control,
    formState: { dirtyFields, errors, touchedFields },
    handleSubmit,
    setError,
    setValue,
    trigger,
  } = useSignupFormContext();

  const projectName = useWatch({ control, name: 'name' });
  const subdomain = useWatch({ control, name: 'subdomain' });

  const handleSaveError = useCallback(
    (error: HTTPError) => {
      // Our fetcher stores the initial response data on `error.info`
      const info = error.info as { errors: Record<string, string> } | { errors: string[] };

      // If we have a 500+ error, set a generic error message
      if (error?.status && error?.status >= 500) {
        setError('root.server', {
          type: 'manual',
          message: 'Something went wrong, please try again.',
        });

        return;
      }

      // Manually set errors from the API response to the form state.
      if (Array.isArray(info?.errors)) {
        info?.errors.forEach(message => {
          setError('root.server', { type: 'manual', message });
        });
      } else {
        Object.entries(info?.errors).forEach(([key, message]) => {
          // We need to map certain server errors to the correct form field
          // or set it as a server error on the root key
          const errorName = mapServerErrors(key) as unknown as Path<SignupFormValues>;

          if (errorName) {
            setError(errorName, { type: 'manual', message });
          }
        });
      }
    },
    [setError],
  );

  const onSubmit = handleSubmit(async data => {
    try {
      setIsLoading(true);

      const project = await fetcher<ProjectClientSide>('/api/projects', {
        method: 'POST',
        body: JSON.stringify({
          ...data,
          appearance: {
            ...data.appearance,
            colors: {
              ...data.appearance?.colors,
              // If the user doesn't select a color, use the default ReadMe blue
              main: data.appearance?.colors?.main || '#018ef5',
            },
            // If the user doesn't upload a logo, use the default ReadMe one
            logo: data.appearance?.logo?.length ? data.appearance.logo : DEFAULT_LOGO,
            // Default to solid header theme option
            theme: 'solid',
          },
          // Set source based on whether this project is created from Signup or direct Dash creation
          source: isDirectNewProjectCreation ? SignupSource.DashNewProject : SignupSource.Signup,
        }),
      });

      // Pass project data back to parent component
      onSuccess(project);
    } catch (error) {
      handleSaveError(error as HTTPError);
    } finally {
      setIsLoading(false);
    }
  });

  const subdomainLookup = useCallback(
    async (slug, manualSubdomain = false) => {
      if (!slug || isSubdomainLookupLoading || !!errors?.subdomain) return;

      try {
        setIsSubdomainLookupLoading(true);
        const response = await fetcher<SubdomainLookupResponse>(`/subdomain-lookup?subdomain=${slug}`, {
          method: 'GET',
        });

        if (response) {
          setIsSubdomainLookupLoading(false);

          // If slug is taken, maybe try again w/ slug + hash
          if (response.exists) {
            setSubdomainLookupExists(true);

            // If this is a manual subdomain entry, we don't need to recursively continue lookup
            if (manualSubdomain) return;

            subdomainLookup(`${slug}-${generateSlugHash()}`);
          } else {
            setValue('subdomain', slug);
            setSubdomainLookupExists(false);
          }
        }
      } catch (error) {
        // This is a non-critical error, so we don't need to do anything
        setIsSubdomainLookupLoading(false);
      }
    },
    [errors?.subdomain, isSubdomainLookupLoading, setValue],
  );

  const debouncedSubdomainLookup = useDebounced(subdomainLookup, 500);

  const subdomainSuffix = useMemo(() => {
    const hasSubdomainError = !!errors?.subdomain;
    if ((subdomainLookupExists === null && !isSubdomainLookupLoading) || !subdomain || hasSubdomainError) return null;

    if (isSubdomainLookupLoading) {
      return <Spinner size="sm" />;
    }

    if (subdomainLookupExists || hasSubdomainError) {
      return <Icon color="yellow" name="alert-triangle" />;
    }

    return <Icon color="green" name="check" />;
  }, [errors?.subdomain, isSubdomainLookupLoading, subdomain, subdomainLookupExists]);

  const subdomainLookupMessage = useMemo(() => {
    if ((subdomainLookupExists === null && !isSubdomainLookupLoading) || !subdomain || !!errors?.subdomain) return null;

    const message = subdomainLookupExists
      ? 'That subdomain is already taken, please try another.'
      : 'That subdomain looks great!';

    return (
      <Flex align="center" gap="xs" justify="start">
        {isSubdomainLookupLoading ? 'Checking subdomain…' : message}
      </Flex>
    );
  }, [errors?.subdomain, isSubdomainLookupLoading, subdomain, subdomainLookupExists]);

  const handleNameChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const text = (event.target as HTMLInputElement).value;

      // If subdomain field has been manually touched, we don't want to auto-generate a subdomain
      if (touchedFields?.subdomain) return;

      // Slugify entered project name and generate subdomain
      const slug = slugify(text);

      debouncedSubdomainLookup(slug);
    },
    [debouncedSubdomainLookup, touchedFields?.subdomain],
  );

  const handleSubdomainChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const text = (event.target as HTMLInputElement).value;

      if (!text) {
        setSubdomainLookupExists(null);
        return;
      }

      // Trigger validation if subdomain is longer than 30 characters
      if (text.length > 30) {
        trigger('subdomain');
        setSubdomainLookupExists(null);
        return;
      }

      // Slugify entered text
      const slug = slugify(text);

      // Clear any existing errors and set value in form
      setValue('subdomain', slug, { shouldValidate: true });

      debouncedSubdomainLookup(slug, true);
    },
    [debouncedSubdomainLookup, setValue, trigger],
  );

  const handleSubdomainKeyPress = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      const re = /^[a-zA-Z0-9- ]*$/;

      // Only allow alphanumeric characters and dashes
      if (!re.test(event.key)) {
        setError('subdomain', {
          type: 'manual',
          message: 'Subdomain must contain only letters, numbers and dashes.',
        });
        event.preventDefault();
        return;
      }

      // Trigger validation on every valid keypress
      trigger('subdomain');
    },
    [setError, trigger],
  );

  const detectDominantColorFromLogo = useCallback(
    (img: HTMLImageElement) => {
      const fac = new FastAverageColor();

      // If user has manually changed primary color field, skip trying to detect and set color
      const isPrimaryColorDirty = !!dirtyFields?.appearance?.colors?.main;

      if (!img || isPrimaryColorDirty) return;

      // Using the preview image, try to get the dominant color
      // and apply it to primary color field (excluding pure white/black)
      try {
        const dominantColor = fac.getColor(img, { algorithm: 'dominant' });
        const hex = dominantColor?.hex?.toLowerCase();
        const isBlackOrWhite = hex === '#000000' || hex === '#ffffff';

        const lighterColor = tinycolor(hex).lighten(10).toString();
        const darkerColor = tinycolor(hex).darken(10).toString();

        // Determine if we should lighten or darken the color based on perceived luminance of dominant color
        const adjustedColor = tinycolor(hex).isLight() ? darkerColor : lighterColor;

        if (adjustedColor && !isBlackOrWhite) {
          // We'll use `shouldTouch` to prevent the form from being marked as dirty
          setValue('appearance.colors.main', adjustedColor, { shouldTouch: true });
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.warn('Unable to get dominant color from image', e);
      }
    },
    [dirtyFields, setValue],
  );

  const projectNameDescription = useMemo(() => {
    // Hide description when subdomain field is shown
    if (showSubdomainField) return null;

    return (
      <>
        {!subdomain ? (
          <Flex align="center" gap="xs" justify="start">
            <span> Enter your project name to generate a subdomain</span>
          </Flex>
        ) : (
          <Flex align="center" gap="xs" justify="start">
            <span>
              Your subdomain will be <b>{subdomain}.readme.io</b>
            </span>
            <Button link onClick={() => setShowSubdomainField(!showSubdomainField)}>
              Change
            </Button>
          </Flex>
        )}
      </>
    );
  }, [showSubdomainField, subdomain]);

  const projectNameSuffix = useMemo(() => {
    // Skip showing any suffix if no lookup has occurred or subdomain field is being shown
    if (!projectName || (subdomainLookupExists === null && !isSubdomainLookupLoading) || showSubdomainField) {
      return null;
    }

    if (!showSubdomainField && isSubdomainLookupLoading) {
      return <Spinner size="sm" />;
    }

    if (!!subdomain && !isSubdomainLookupLoading && !subdomainLookupExists) {
      return (
        <Tooltip content="That project name and subdomain look great!" delay={[300, 0]}>
          <Icon color="green" name="check" />
        </Tooltip>
      );
    }

    return null;
  }, [isSubdomainLookupLoading, projectName, showSubdomainField, subdomain, subdomainLookupExists]);

  const hasError = !!errors?.root?.server;
  const errorMessage = errors?.root?.server?.message;

  return (
    <form className={bem('&')} onSubmit={onSubmit}>
      <Flex align="start" className={bem('-main-container')} gap="0" grow="1" inline="true" layout="col">
        <RHFGroup
          className={bem('&-width-100')}
          control={control}
          description={projectNameDescription}
          id="name"
          label="Project Name"
          name="name"
          required
        >
          {({ field }) => (
            <Input
              {...field}
              autoComplete="off"
              autoFocus
              data-1p-ignore
              onChange={e => {
                field.onChange(e);
                handleNameChange(e);
              }}
              placeholder="Wonderful Docs"
              suffix={projectNameSuffix}
            />
          )}
        </RHFGroup>

        {/* Show subdomain field if user has clicked "Change" button or if there's an error */}
        {!!(showSubdomainField || !!errors.subdomain) && (
          <RHFGroup
            className={bem('&-width-100')}
            control={control}
            description={subdomainLookupMessage}
            id="subdomain"
            label="Subdomain"
            maxLength={30}
            name="subdomain"
            required
          >
            {({ field }) => (
              <InputGroup columnLayout="1fr auto auto" separators={false}>
                <Input
                  {...field}
                  autoComplete="off"
                  autoFocus
                  onChange={e => {
                    field.onChange(e);
                    handleSubdomainChange(e);
                  }}
                  onKeyPress={handleSubdomainKeyPress}
                  placeholder="wonderful-docs.readme.io"
                />
                <Flex align="center" justify="center" style={{ padding: 'var(--sm)' }}>
                  .readme.io
                </Flex>
                <Flex align="center" justify="center" style={{ padding: 'var(--sm)' }}>
                  {subdomainSuffix}
                </Flex>
              </InputGroup>
            )}
          </RHFGroup>
        )}

        <div className={bem('&-flex-container')}>
          <RHFGroup className={bem('&-logo-group')} control={control} id="logo" label="Logo" name="appearance.logo">
            {({ field }) => (
              <Flex align="stretch" gap={0} layout="col">
                <ImageUploader
                  data={field.value && typeof field.value === 'object' ? field.value : undefined}
                  id={field.id}
                  isLegacyApi
                  kind="primary"
                  maxHeight={80}
                  onFinish={data => {
                    field.onChange(data || null);
                  }}
                  onPreviewLoad={detectDominantColorFromLogo}
                  preview
                >
                  <Icon name="upload" />
                  Upload Image
                </ImageUploader>
              </Flex>
            )}
          </RHFGroup>
          <RHFGroup
            className={bem('&-width-100')}
            control={control}
            id="brandColor"
            label="Brand Color"
            name="appearance.colors.main"
          >
            {({ field }) => (
              <ColorPicker
                circular={false}
                className={bem('-color-picker')}
                color={field.value}
                debounceOnChange={10}
                id={field.id}
                onChange={field.onChange}
                placeholder="#FFFFFF"
                wrapperClassName={bem('-color-picker-wrapper')}
              />
            )}
          </RHFGroup>
        </div>
      </Flex>

      <div className={bem('-cta-container')}>
        {/* Show any submit/server errors below fields */}
        {!!hasError && (
          <small className={bem('-form-group-margin-top', 'FormGroup FormGroup-error')} role="note">
            {errorMessage}
          </small>
        )}
        <hr className={bem('-divider')} />
        {!!isGod && (
          <RHFGroup className={bem('-superhub-toggle')} control={control} name="flags.superHub">
            {({ field }) => (
              <Toggle
                checked={field.value}
                isLabelMuted
                label={
                  <span className={bem('-superhub-toggle-label')}>
                    Enable SuperHub
                    <Icon className={bem('-superhub-toggle-icon')} color="yellow20" name="github-filled" />
                  </span>
                }
                onChange={event => {
                  field.onChange(event.target.checked);
                }}
                size="sm"
                type="toggle"
              />
            )}
          </RHFGroup>
        )}
        <Button
          className={bem('-submit-btn')}
          disabled={!projectName || !subdomain}
          fullWidth
          loading={isLoading}
          type="submit"
        >
          Continue
          {!isLoading && <Icon color="white" name="arrow-right" />}
        </Button>
      </div>
    </form>
  );
};

export default CreateProjectForm;
