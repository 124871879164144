import React, { useMemo } from 'react';

import useClassy from '@core/hooks/useClassy';

import Orb from '@ui/Onboarding/Orb';

import styles from './style.module.scss';

// Default padding when positioning along the edges
const offsetPercent = '20%';

// True if a string starts with `rgb`, `hsl`, `#`, `var`, `linear-gradient`, or `radial-gradient`.
function isCSSColorUnit(str: string) {
  const regex = /^(rgb|hsl|#|var|linear-gradient|radial-gradient)/;
  return regex.test(str);
}

type OrbPlacement =
  | 'bottom-end'
  | 'bottom-start'
  | 'bottom'
  | 'center'
  | 'left-end'
  | 'left-start'
  | 'left'
  | 'right-end'
  | 'right-start'
  | 'right'
  | 'top-end'
  | 'top-start'
  | 'top';

interface Props {
  TagName?: string;
  background?: string;
  borderRadius?: number;
  children?: React.ReactNode;
  className?: string;
  contentClassName?: string;
  isEmbossed?: boolean;
  isOverflowVisible?: boolean;
  /** Orb types */
  orbAlpha?: number;
  orbColors?: string[];
  /** In rems */
  orbOffset?: number[];
  /** In rems */
  orbPlacement?: OrbPlacement;
  /** In rems */
  orbSize?: number;
  padding?: number | string;
  /**
   * Content is rendered inside a `<div>` by default. Use this prop to render it
   * inside a different tag instead.
   */
  tag?: React.ElementType;
  width?: string;
}

const FancyCard = ({
  background,
  borderRadius = 1,
  children,
  className = '',
  contentClassName = '',
  isEmbossed = false,
  isOverflowVisible = false,
  orbColors,
  orbAlpha = 0.2,
  orbSize = 50,
  padding = 'inherit',
  orbPlacement = 'center',
  orbOffset = [],
  tag: Tag = 'div',
  width = 'auto',
}: Props) => {
  const bem = useClassy(styles, 'FancyCard');

  const cardStyle = useMemo(
    () => ({
      background: background ? (isCSSColorUnit(background) ? background : `var(--${background})`) : null,
      borderRadius: `${borderRadius}em ${borderRadius}em ${borderRadius}em ${borderRadius}em`,
      padding: typeof padding === 'number' ? `${padding}rem` : padding,
      width,
    }),
    [borderRadius, width, padding, background],
  );

  // Position the orbs based on the placement & offset prop
  const [orbX = 0, orbY = 0] = orbOffset;
  const orbContainerStyle = useMemo(() => {
    const [quadrant, direction] = orbPlacement.split('-');
    const isVertical = quadrant === 'top' || quadrant === 'bottom';

    const startPos = `calc(0% + ${offsetPercent})`;
    const centerPos = '50%';
    const endPos = `calc(100% - ${offsetPercent})`;

    // center by default if no placement prop is provided
    let top = centerPos;
    let left = centerPos;

    switch (quadrant) {
      case 'top':
        top = startPos;
        left = centerPos;
        break;
      case 'right':
        top = centerPos;
        left = endPos;
        break;
      case 'bottom':
        top = endPos;
        left = centerPos;
        break;
      case 'left':
        top = centerPos;
        left = startPos;
        break;
      default:
        break;
    }

    switch (direction) {
      case 'start': {
        if (isVertical) {
          left = startPos;
        } else {
          top = startPos;
        }
        break;
      }
      case 'end':
        if (isVertical) {
          left = endPos;
        } else {
          top = endPos;
        }
        break;
      default:
        break;
    }

    return {
      height: `${orbSize}rem`,
      width: `${orbSize}rem`,
      // set the transform origin to center
      marginLeft: `-${orbSize / 2}rem`,
      marginTop: `-${orbSize / 2}rem`,
      // placement
      top,
      left,
      // offset
      transform: `translate(${orbX}rem, ${orbY}rem)`,
    };
  }, [orbSize, orbPlacement, orbX, orbY]);

  const orbs = useMemo(
    () =>
      orbColors &&
      orbColors.map((color, i) => <Orb key={`${i}_${color}`} alpha={orbAlpha} color={color} size={orbSize} />),
    [orbColors, orbSize, orbAlpha],
  );

  return (
    <Tag
      className={bem('&', isEmbossed && '_embossed', isOverflowVisible && '_overflow-visible', className)}
      style={cardStyle}
    >
      {!!orbs && (
        <div className={bem('_orbs')} style={orbContainerStyle}>
          <div className={bem('_orbs-cloud')}>{orbs}</div>
        </div>
      )}

      <div className={bem('-content', contentClassName)}>{children}</div>
    </Tag>
  );
};

export default React.memo(FancyCard);
